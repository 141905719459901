<template>
  <transition
      name="container"
      appear
  >
    <div class="container">
      <div
          v-show="canShowContent"
          class="content"
      >
        <tour-item
            v-for="type in businessType"
            v-show="type === activeBusinessType"
            :key="type"
            :type="type"
            :is-active="type === activeBusinessType"
            :spots="activeTourSpots"
            :is-mobile="isMobile"
            :active-resolution="activeResolution"
        />
      </div>
      <div
          v-show="!canShowContent"
          class="cant-show"
      >
        {{ $t('rotateDeviceWarning') }}
      </div>
      <Footer
          :active-tour="activeTourName"
          :tours="toursNames"
          @click-tour="_onClickTour"
      />
    </div>
  </transition>
</template>

<script>
import tours from '@/tours'
import mobile from 'is-mobile'
import Footer from './Footer'
import { EUROPE_LOCALES } from '@/constants'
import TourItem from '../tour/TourItem'

export default {
  name: 'Container',
  components: {
    Footer,
    TourItem
  },
  data() {
    return {
      activeBusinessType: 'fleet',
      activeTourName: 'scalableSolution',
      canShowContent: true,
      businessType: ['commercial', 'fleet']
    }
  },
  computed: {
    isMobile() {
      return mobile({tablet: true});
    },

    activeResolution() {
      const urlParams = new URLSearchParams(window.location.search)
      const lang = urlParams.get('lang')

      if (EUROPE_LOCALES.includes(lang)) {
        return this.isMobile ? 'euMobile' : 'eu'
      }

      return this.isMobile ? 'naMobile' : 'na'
    },

    toursNames() {
      return Object.keys(tours[this.activeBusinessType])
    },

    activeTourSpots() {
      return tours[this.activeBusinessType][this.activeTourName]
    }
  },
  mounted() {
    this._onOrientationChange()
    window.addEventListener('resize', this._onOrientationChange)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this._onOrientationChange)
  },
  methods: {
    _onClickTour(tourName) {
      this.activeTourName = tourName
    },

    _handleBusinessTypeChange(value) {
      this.activeBusinessType = value
      this.activeTourName = 'scalableSolution'
    },

    _onOrientationChange() {
      this.$nextTick(() => {
        this.canShowContent = (window.innerHeight >= 560)
      })
    }
  }
}
</script>

<style lang="scss">
@import "@vars";
@import "@mixins";

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 24px;

  @include media-down($lg) {
    height: 100%;
  }

  @include media-down($md) {
    padding: 16px 16px 24px;
  }
}

.header {
  margin-bottom: 65px;

  @include media-down($lg) {
    margin-bottom: 24px;
  }
}

.content {
  align-items: center;
  display: flex;
  flex: 1;
  margin-bottom: 24px;
  min-height: 0;
}

.cant-show {
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex: 1;
  margin: 0 auto;
}

@keyframes container-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.container-enter-active {
  animation: container-in .5s ease;
}

.container-leave-active {
  animation: container-in .5s reverse ease;
}
</style>


<template>
  <div class="tour">
    <div class="tour__station">
      <div class="tour__station-inner">
        <template v-if="serviceIsLoaded">
        <img
            src="/assets/images/arrow-left.svg"
            class="tour__rotate-icon-left"
          />
          <img
            src="/assets/images/arrow-right.svg"
            class="tour__rotate-icon-right"
          />
        </template>
        <canvas
          id="station-canvas"
          ref="canvasRef"
          class="tour__station-canvas"
        />
        <div v-if="!serviceIsLoaded" class="tour__loader-wrapper">
          <img src="/assets/images/loader.png" class="tour__loader" />
        </div>
        <spot-item
          v-for="spot in frontSideSpots"
          v-show="!isCanvasActive && firstFrameIsDrawed"
          :id="spot.id"
          :key="spot.id"
          v-click-outside="_onClickOutside"
          :title="spot.title"
          :position="spot.pointPosition"
          :title-position="spot.titlePosition"
          :line-height="spot.lineHeight"
          :line-width="spot.lineWidth"
          :is-mobile="isMobile"
          :scale="scale"
          :active-spot-id="activeSpotId"
          @select-spot="_onSelectSpot"
          @enter-spot="_onEnterSpot"
          @leave-spot="_onLeaveSpot"
        />
      </div>
      <div class="tour__station-title">Express</div>
    </div>

    <!--    <div class="tour__station-back-wrapper">-->
    <!--      <div class="tour__station-back">-->
    <!--        <div ref="wrapper" class="tour__station-back-inner">-->
    <!--          <img-->
    <!--            ref="image"-->
    <!--            src="/assets/images/station-back.jpg"-->
    <!--            class="tour__station-back-image"-->
    <!--          />-->
    <!--          <spot-item-->
    <!--            v-for="spot in backSideSpots"-->
    <!--            v-show="!isCanvasActive && firstFrameIsDrawed"-->
    <!--            :id="spot.id"-->
    <!--            :key="spot.id"-->
    <!--            v-click-outside="_onClickOutside"-->
    <!--            :title="spot.title"-->
    <!--            :position="spot.pointPosition"-->
    <!--            :title-position="spot.titlePosition"-->
    <!--            :line-height="spot.lineHeight"-->
    <!--            :line-width="spot.lineWidth"-->
    <!--            :is-mobile="isMobile"-->
    <!--            :scale="scale"-->
    <!--            :active-spot-id="activeSpotId"-->
    <!--            @select-spot="_onSelectSpot"-->
    <!--            @enter-spot="_onEnterSpot"-->
    <!--            @leave-spot="_onLeaveSpot"-->
    <!--          />-->
    <!--        </div>-->
    <!--        <div class="tour__station-title">-->
    <!--          Power Block-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->

    <template v-if="activeSpot">
      <transition name="spot-info" appear>
        <div
          v-if="activeSpot.infoPosition !== 'center'"
          v-show="activeSpotId"
          :key="activeSpotId"
          class="tour__spot-info-outer"
          :style="outerSpotInfoPosition"
        >
          <div class="tour__spot-info-wrapper">
            <spot-info
              :active-spot-id="activeSpotId"
              :title="title"
              :text="text"
              :video-url="videoUrl"
              :video-preview-url="videoPreviewUrl"
              @close="_onCloseSpotInfo"
            />
          </div>
        </div>
        <div
          v-else
          v-show="activeSpotId"
          :key="activeSpotId"
          class="tour__spot-info-split-outer"
        >
          <spot-info-split
            :active-spot-id="activeSpotId"
            :title="title"
            :text="text"
            :video-url="videoUrl"
            :video-preview-url="videoPreviewUrl"
            :text-style="textPosition"
            :video-style="videoPosition"
            @close="_onCloseSpotInfo"
          />
        </div>
      </transition>
    </template>
  </div>
</template>

<script>
import StationService from "@/services/Station";
import SpotItem from "@/components/spot/SpotItem";
import SpotInfo from "@/components/spot/SpotInfo";
import SpotInfoSplit from "@/components/spot/SpotInfoSplit";

export default {
  name: "TourItem",
  components: {
    SpotItem,
    SpotInfo,
    SpotInfoSplit,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    spots: {
      type: Array,
      default: () => [],
    },
    activeResolution: {
      type: String,
      required: true,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      totalFrames: 300,
      service: null,
      frame: -1,
      availableFrames: [],
      scale: 1,
      isCanvasActive: false,
      firstFrameIsDrawed: false,
      isSpotClicked: false,
      activeSpot: null,
    };
  },
  computed: {
    activeSpotId() {
      return this.activeSpot ? this.activeSpot.id : null;
    },

    serviceIsLoaded() {
      return this.service && this.service.isLoaded;
    },

    frontSideSpots() {
      return this.spots.filter((spot) => spot.stationSide === "front");
    },

    backSideSpots() {
      return this.spots.filter((spot) => spot.stationSide === "back");
    },

    title() {
      if (!this.activeSpot) {
        return "";
      }

      return this.activeSpot.infoTitle
        ? this.activeSpot.infoTitle
        : this.activeSpot.title;
    },

    text() {
      return this.activeSpot ? this.activeSpot.text : "";
    },

    videoUrl() {
      return this.activeSpot && this.activeSpot.videoUrl
        ? `${window.location.protocol}//${window.location.host}${this.activeSpot.videoUrl}`
        : "";
    },

    videoPreviewUrl() {
      return this.activeSpot && this.activeSpot.previewUrl
        ? `${window.location.protocol}//${window.location.host}${this.activeSpot.previewUrl}`
        : "";
    },

    outerSpotInfoPosition() {
      if (this.isMobile || !this.activeSpot) {
        return null;
      }

      return {
        [this.activeSpot.infoPosition]: 0,
        "justify-content":
          this.activeSpot.infoPosition === "left" ? "flex-end" : "flex-start",
      };
    },

    textPosition() {
      return this.activeSpot ? this.activeSpot.infoTextStyle : {};
    },

    videoPosition() {
      return this.activeSpot ? this.activeSpot.infoVideoStyle : {};
    },
  },
  watch: {
    activeResolution: {
      handler() {
        this.initService();
      },
    },
    isActive: {
      immediate: true,
      handler(value) {
        if (value && !this.service) {
          this.initService();
        }

        if (value) {
          if (this.service) {
            this.service.initCanvas();
          }
          this._setImageWrapperWidth();
        }
      },
    },
    spots() {
      this._clearState();
    },
  },
  mounted() {
    this.$refs.canvasRef.addEventListener("resize", this._onResize);
    this.$refs.canvasRef.addEventListener("draw-frame", ({ detail }) => {
      if (detail.frame === this.availableFrames[0]) {
        this.firstFrameIsDrawed = true;
      }
    });
    this.$refs.canvasRef.addEventListener("mousedown", this._onDown);
    this.$refs.canvasRef.addEventListener("touchstart", this._onDown);
    window.addEventListener("mouseup", this._onUp); // todo: получается обработчик на каждый сервис
    window.addEventListener("touchend", this._onUp);

    // todo: костыль, т.к. на некоторых браузерах неправильно счиатется ширина родительского дива картинки
    const image = this.$refs.image;
    image.addEventListener("load", this._onLoadImage);
  },
  beforeUnmount() {
    this.service && this.service.dispose();
    this.$refs.canvasRef.removeEventListener("mousedown", this._onDown);
    this.$refs.canvasRef.removeEventListener("touchstart", this._onDown);
    window.removeEventListener("mouseup", this._onUp);
    window.removeEventListener("touchend", this._onUp);
  },
  methods: {
    _onLoadImage() {
      const image = this.$refs.image;
      const wrapper = this.$refs.wrapper;
      if (this.isActive) {
        wrapper.style.width = `${image.clientWidth}px`;
      }
      window.addEventListener("resize", this._setImageWrapperWidth);
    },
    _setImageWrapperWidth() {
      const wrapper = this.$refs.wrapper;
      const image = this.$refs.image;
      if (!wrapper || !image) {
        return;
      }
      if (this.isActive) {
        wrapper.style.width = `${image.clientWidth}px`;
      }
    },
    initService() {
      this.service && this.service.dispose();
      this._getAvailableFrames().then((availableFrames) => {
        this.availableFrames = availableFrames;
        this.frame = this.availableFrames[0];
        this.service = new StationService({
          node: this.$refs.canvasRef,
          imagesURLPattern:
            window.location.protocol +
            "//" +
            window.location.host +
            `/assets/frames/cpe250/{frame}.jpg?` +
            Date.now(),
          keyFrames: this.availableFrames,
          startFrame: this.frame,
          endFrame: [...this.availableFrames].pop(),
          frameWidth: 750,
          frameHeight: 1300,
          currentFrame: this.frame,
        });
        this.service.load().then(() => {
          this.service.drawCurrentFrame();
          this.service.animate();
        });
      });
    },

    _getAvailableFrames() {
      return new Promise((resolve) => {
        // todo: зачем тут промис?
        const totalFramesNumbers = [...Array(this.totalFrames).keys()];
        resolve(totalFramesNumbers);
      });
    },

    _onDown() {
      this.isCanvasActive = true;
    },

    _onUp() {
      if (this.service && this.service.startMove) {
        this.$refs.canvasRef.addEventListener(
          "tween-to-frame",
          this._onAnimationEnd
        );
      }
    },

    _onAnimationEnd(event) {
      if (
        event.detail &&
        (event.detail.frame === this.frame ||
          event.detail.frame === [...this.availableFrames].pop())
      ) {
        this.isCanvasActive = false;
        this.$refs.canvasRef.removeEventListener(
          "tween-to-frame",
          this._onAnimationEnd
        );
      }
    },

    _onResize() {
      if (this.isActive) {
        this.$nextTick(() => {
          if (this.$refs.canvasRef && this.frame >= -1) {
            const dpr = window.devicePixelRatio || 1;
            const canvasHeight = this.$refs.canvasRef.height;
            const frameHeight = this.service.frameHeight;
            if (canvasHeight / dpr < frameHeight) {
              this.scale = canvasHeight / dpr / frameHeight;
            }
          }
        });
      }
    },

    _clearState() {
      this.isSpotClicked = false;
      this.activeSpot = null;
    },

    _onSelectSpot(spotId) {
      this.isSpotClicked = true;
      this.activeSpot = this.spots.find(({ id }) => id === spotId);

      document.body.style.overflow = "hidden";
    },

    _onEnterSpot(spotId) {
      if (!this.isSpotClicked) {
        this.activeSpot = this.spots.find(({ id }) => id === spotId);
      }
    },

    _onLeaveSpot() {
      if (
        !this.isSpotClicked &&
        window.innerWidth > 1024 &&
        this.activeSpot.infoPosition !== "center"
      ) {
        this.activeSpot = null;
      }
    },

    _onClickOutside() {
      if (
        window.innerWidth > 1024 &&
        this.activeSpot &&
        this.activeSpot.infoPosition !== "center"
      ) {
        this.isSpotClicked = false;
        this.activeSpot = null;
      }
    },

    _onCloseSpotInfo() {
      // todo: использовать _clearState
      this.isSpotClicked = false;
      this.activeSpot = null;

      document.body.style.overflow = "visible";
    },
  },
};
</script>

<style lang="scss">
@import "@vars";
@import "@mixins";

.tour {
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: minmax(0, 1fr) 100% minmax(0, 1fr);
  grid-template-areas: "..... station back";
  position: relative;
  width: 100%;
  height: 100%;

  @include media-down($lg) {
    align-items: center;
    display: flex;
    justify-content: center;
    // height: 52vh;
  }

  @include media-down($md) {
    flex-direction: column;
    height: auto;
    padding: 0;
  }

  &__station {
    align-items: center;
    display: flex;
    grid-area: station;
    flex-direction: column;
    justify-content: center;
    position: relative;
    height: 100%;

    @include media-down($lg) {
      height: 100%;
      margin: 0 50px 0 0;
    }

    @include media-down($md) {
      height: 42vh;
      margin: 10px 0 35px;
    }

    &-title {
      color: #555;
      font-size: 20px;
      font-weight: bold;
      text-align: center;

      @include media-down($lg) {
        font-size: 18px;
      }
    }

    &-inner {
      height: 100%;
      min-height: 0;
      min-width: 0;
      position: relative;
    }

    &-canvas {
      height: 100%;
      object-fit: contain;
      max-width: 100%;
      padding: 0 40px;
    }

    &-back {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 100%;
      min-width: 0;

      @include media-down($md) {
        margin: 0;
      }

      &-wrapper {
        align-items: center;
        display: flex;
        grid-area: back;

        @include media-down($lg) {
          height: 100%;
        }

        @include media-down($md) {
          height: 50vh;
        }
      }

      &-inner {
        height: 75%;
        margin: 0 0 9%;
        min-height: 0;
        min-width: 0;
        position: relative;

        @include media-down($md) {
          height: 100%;
          margin: 0;
        }
      }

      &-image {
        max-height: 100%;
        object-fit: contain;
      }
    }
  }

  &__rotate-icon-left,
  &__rotate-icon-right {
    pointer-events: none;
    position: absolute;
    top: 60%;
    height: 41px;
    user-select: none;
    z-index: 1;

    @include media-down($md) {
      top: 62%;
      height: 35px
    }
  }

  &__rotate-icon-left {
    right: 93%;

     @include media-down($lg) {
      right: 93%;
    }

    @include media-down($md) {
      right: 86%;
    }
  }

  &__rotate-icon-right {
    left: 95%;

     @include media-down($lg) {
      left: 93%;
    }

    @include media-down($md) {
      left: 86%;
    }
  }

  &__loader-wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    user-select: none;
  }

  &__loader {
    animation: loading 1s linear infinite;
    @include size(120px);

    @include media-down($lg) {
      @include size(50px);
    }
  }

  &__spot-info-outer {
    align-items: center;
    display: flex;
    position: absolute;
    top: 0;
    height: 100%;
    width: 37%;

    @include media-down($lg) {
      width: 100%;
      z-index: 3;
    }

    @include media-down($md) {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  &__spot-info-wrapper {
    align-items: center;
    display: flex;
    max-width: 450px;
    height: 100%;

    @include media-down($lg) {
      max-height: none;
      max-width: none;
      height: 100%;
      position: static;
      transform: none;
      width: 100%;
    }
  }

  &__spot-info-split-outer {
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 3;

    @include media-down($md) {
      position: fixed;
    }
  }
}

@keyframes loading {
  to {
    transform: rotate(360deg);
  }
}

.spot-info-enter-active,
.spot-info-leave-active {
  transition: opacity 0.4s;
}
.spot-info-enter,
.spot-info-leave-to {
  opacity: 0;
}
</style>

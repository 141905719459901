<template>
  <div class="cp-circle-button" @click="$emit('click')">
    <img src="/assets/images/arrow-back.png" />
  </div>
</template>

<script>
export default {
  name: "CpCircleButton",
};
</script>

<style lang="scss">
@import "@vars";
@import "@mixins";

.cp-circle-button {
  align-items: center;
  color: #567a92;
  cursor: pointer;
  display: flex;
  justify-content: center;
  font-size: 20px;
  width: 30px;

  @include media-down($lg) {
    font-size: 14px;
    width: 30px;
    display: none;
  }

  img {
    max-width: 100%;
    object-fit: contain;
  }
}
</style>

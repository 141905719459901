import en from './dictionaries/en/index.json'
import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const langsCollection = [
  'en',
  'fr-ca',
]

export const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: { en },
  // silentTranslationWarn: true если надо убрать warnings
})

const urlParams = new URLSearchParams(window.location.search)
const localeName = urlParams.has('lang') ? urlParams.get('lang') : 'en'

if (langsCollection.includes(localeName)) {
  import(`./dictionaries/${localeName}/index.json`)
    .then((msgs) => {
      i18n.setLocaleMessage(localeName, msgs.default)
      i18n.locale = localeName
    })
    .catch(() => {})
}

export default i18n

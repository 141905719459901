import { EUROPE_LOCALES } from "@/constants";

const urlParams = new URLSearchParams(window.location.search);
const lang = urlParams.has('lang') ? urlParams.get('lang') : '';
const americanLangs = [...EUROPE_LOCALES];
const allLangs = [...americanLangs];

const elementsEn = [
    "Station owners can set how power is shared between paired stations with Power Allocation Polices set through the Cloud. These policies are:",
    "1. First Come, First Served",
    "2. Equal Share",
    "3. Fixed Allocation",
];

const elementsFrCa = [
    "Les propriétaires de borne peuvent définir la manière dont la puissance est partagée entre les bornes couplées, les politiques de répartition étant définies via le cloud. Ces différentes politiques sont les suivantes :",
    "1. Premier arrivé, premier servi",
    "2. Partage égal",
    "3. Allocation fixe",
];

const powerAllocationDescriptionFrCa = elementsFrCa.join("<br/>");

const powerAllocationDescriptionEn = elementsEn.join("<br/>");

const descriptionPath = allLangs.includes(lang) ? powerAllocationDescriptionFrCa : powerAllocationDescriptionEn;

export default {
    fleet: {
        scalableSolution: [
            {
                id: 'charge-point-cloud-services',
                stationSide: 'front',
                pointPosition: [60, 630], // 60 630
                videoUrl: ``,
                previewUrl: '',
                title: 'chargePointTitle',
                text: 'chargePointDescr',
                lineHeight: 0,
                lineWidth: 10,
                titlePosition: 'left',
                infoPosition: 'right',
                infoTextStyle: { position: 'absolute', left: '70px', top: '23%', width: '230px' },
                infoVideoStyle: { width: '75%' }
            },
            {
                id: 'status-display',
                stationSide: 'front',
                pointPosition: [190, 630], // 170 630
                videoUrl: ``,
                previewUrl: '',
                title: 'statusDisplayTitle',
                text: 'statusDisplayDescr',
                lineHeight: 0,
                lineWidth: 10,
                titlePosition: 'left',
                infoPosition: 'right',
                infoTextStyle: { position: 'absolute', left: '70px', top: '23%', width: '230px' },
                infoVideoStyle: { width: '20%' }
            },
            {
                id: 'lcd-screen',
                stationSide: 'front',
                pointPosition: [520, 435], // 520 455
                videoUrl: ``,
                previewUrl: '',
                title: 'lcdScreenTitle',
                text: 'lcdScreenDescr',
                lineHeight: 100,
                lineWidth: 170,
                titlePosition: 'left',
                infoPosition: 'right',
                infoTextStyle: { position: 'absolute', left: '70px', top: '23%', width: '230px' },
                infoVideoStyle: { width: '75%' }
            },
            {
                id: 'custom-branded-panels',
                stationSide: 'front',
                pointPosition: [380, 320], // 360 325
                videoUrl: ``,
                previewUrl: '',
                title: 'customBrandedPanelsTitle',
                text: 'customBrandedPanelsDescr',
                lineHeight: 200,
                lineWidth: 225,
                titlePosition: 'right',
                infoPosition: 'right',
                infoTextStyle: { position: 'absolute', left: '70px', top: '23%', width: '230px' },
                infoVideoStyle: { width: '20%' }
            },
            {
                id: 'connectivity',
                stationSide: 'front',
                pointPosition: [680, 770],
                videoUrl: ``,
                previewUrl: '',
                title: 'connectivityCPE250Title',
                text: 'connectivityCPE250Descr',
                lineHeight: 0,
                lineWidth: 10,
                titlePosition: 'left',
                infoPosition: 'right',
                infoTextStyle: { position: 'absolute', left: '70px', top: '23%', width: '230px' },
                infoVideoStyle: { width: '27%' }
            },
            {
                id: 'charge-everywhere',
                stationSide: 'front',
                pointPosition: [1120, 400], // 1120 400
                videoUrl: '',
                previewUrl: '',
                title: 'chargeEverywhereCPE250Title',
                text: 'chargeEverywhereCPE250Descr',
                lineHeight: 110,
                lineWidth: 305,
                titlePosition: 'right',
                infoPosition: 'left',
                infoTextStyle: { position: 'absolute', left: '70px', top: '23%', width: '230px' },
            },
            {
                id: 'waitlist',
                stationSide: 'front',
                pointPosition: [680, 355],
                videoUrl: ``,
                previewUrl: '',
                title: 'waitlistTitle',
                text: 'waitlistDescr',
                lineHeight: 200,
                lineWidth: 260,
                titlePosition: 'right',
                infoPosition: 'left',
                infoTextStyle: { position: 'absolute', left: '70px', top: '23%', width: '230px' },
                infoVideoStyle: { width: '27%' }
            }
        ],
        lifetimeCost: [
            {
                id: 'cable-management-swing-arm',
                stationSide: 'front',
                pointPosition: [60, 620], // 60 570
                videoUrl: ``,
                previewUrl: '',
                title: 'cableManagementSwingArmTitle',
                text: 'cableManagementSwingArmDescr',
                lineHeight: 45,
                lineWidth: 70,
                titlePosition: 'left',
                infoPosition: 'right',
                infoTextStyle: { position: 'absolute', left: '70px', top: '23%', width: '260px' },
                infoVideoStyle: { width: '60%' }
            },
            {
                id: 'area-lighting',
                stationSide: 'front',
                pointPosition: [170, 620], // 170 570
                videoUrl: ``,
                previewUrl: '',
                title: 'areaLightingTitle',
                text: 'areaLightingDescr',
                lineHeight: 0,
                lineWidth: 10,
                titlePosition: 'left',
                infoPosition: 'right',
                infoTextStyle: { position: 'absolute', left: '70px', top: '23%', width: '230px' },
                infoVideoStyle: { width: '20%' }
            },
            {
                id: 'field-replaceable-cables',
                stationSide: 'front',
                pointPosition: [100, 240], // 100 190
                videoUrl: ``,
                previewUrl: '',
                title: 'fieldReplaceableCablesTitle',
                text: 'fieldReplaceableCablesDescr',
                lineHeight: 50,
                lineWidth: 60,
                titlePosition: 'right',
                infoPosition: 'right',
                infoTextStyle: { position: 'absolute', left: '70px', top: '23%', width: '260px' },
                infoVideoStyle: { width: '55%' }
            },
            {
                id: 'liquid-cooling',
                stationSide: 'front',
                pointPosition: [460, 290], // 460 240
                videoUrl: ``,
                previewUrl: '',
                title: 'liquidCoolingTitle',
                text: 'liquidCoolingDescr',
                lineHeight: 130,
                lineWidth: 195,
                titlePosition: 'right',
                infoPosition: 'right',
                infoTextStyle: { position: 'absolute', left: '70px', top: '23%', width: '260px' },
                infoVideoStyle: { width: '20%' }
            },
            {
                id: 'venting',
                stationSide: 'front',
                pointPosition: [770, 620], // 770 570
                videoUrl: ``,
                previewUrl: '',
                title: 'ventingTitle',
                text: 'ventingDescr',
                lineHeight: 130,
                lineWidth: 220,
                titlePosition: 'left',
                infoPosition: 'right',
                infoTextStyle: { position: 'absolute', left: '70px', top: '23%', width: '260px' },
                infoVideoStyle: { width: '20%' }
            },
            {
                id: 'station-and-power-modules',
                stationSide: 'front',
                pointPosition: [1080, 620], // 1050 470
                videoUrl: ``,
                previewUrl: '',
                title: 'stationAndPowerModulesTitle',
                text: 'stationAndPowerModulesDescr',
                lineHeight: 130,
                lineWidth: 220,
                titlePosition: 'left',
                infoPosition: 'right',
                infoTextStyle: { position: 'absolute', left: '70px', top: '23%', width: '260px' },
                infoVideoStyle: { width: '50%' }
            },
            {
                id: 'small-footprint',
                stationSide: 'front',
                pointPosition: [1170, 180], // 1170 130
                videoUrl: ``,
                previewUrl: '',
                title: 'smallFootprintTitle',
                text: 'smallFootprintDescr',
                lineHeight: 0,
                lineWidth: 10,
                titlePosition: 'right',
                infoPosition: 'right',
                infoTextStyle: { position: 'absolute', left: '70px', top: '23%', width: '260px' },
                infoVideoStyle: { width: '35%' }
            },
            {
                id: 'chargePoint-assure',
                stationSide: 'front',
                pointPosition: [610, 105], // 610 55
                videoUrl: '',
                previewUrl: '/assets/preview/assure.png',
                title: 'chargePointAssureTitle',
                text: 'chargePointAssureDescr',
                lineHeight: 0,
                lineWidth: 10,
                titlePosition: 'right',
                infoPosition: 'left'
            },
            {
                id: 'support',
                stationSide: 'front',
                pointPosition: [720, 105], // 720 55
                videoUrl: '',
                previewUrl: '/assets/preview/support.png',
                title: 'supportCPE250Title',
                text: 'supportCPE250Descr',
                lineHeight: 0,
                lineWidth: 10,
                titlePosition: 'right',
                infoPosition: 'left'
            },
        ],
        businessIntegrated: [
            {
                id: 'pairing-functionality',
                stationSide: 'front',
                pointPosition: [50, 460], // 50 410
                videoUrl: ``,
                previewUrl: '',
                title: 'pairingFunctionalityTitle',
                text: 'pairingFunctionalityDescr',
                lineHeight: 40,
                lineWidth: 100,
                titlePosition: 'left',
                infoPosition: 'right',
                infoTextStyle: { position: 'absolute', left: '70px', top: '23%', width: '260px' },
                infoVideoStyle: { width: '33%' }
            },
            {
                id: 'power-sharing',
                stationSide: 'front',
                pointPosition: [730, 460], // 730 410
                videoUrl: ``,
                previewUrl: '',
                title: 'powerSharingTitle',
                text: 'powerSharingDescr',
                lineHeight: 150,
                lineWidth: 250,
                titlePosition: 'left',
                infoPosition: 'right'
            },
            {
                id: 'station-redundancy',
                stationSide: 'front',
                pointPosition: [100, 270], // 100 220
                videoUrl: ``,
                previewUrl: '',
                title: 'stationRedundancyTitle',
                text: 'stationRedundancyDescr',
                lineHeight: 80,
                lineWidth: 145,
                titlePosition: 'right',
                infoPosition: 'left'
            },
            {
                id: 'power-allocation',
                stationSide: 'front',
                pointPosition: [650, 295], // 650 245
                videoUrl: ``,
                previewUrl: '',
                title: 'powerAllocationTitle',
                text: descriptionPath,
                lineHeight: 110,
                lineWidth: 170,
                titlePosition: 'right',
                infoPosition: 'left'
            },
            {
                id: 'power-management',
                stationSide: 'front',
                pointPosition: [250, 630], // 250 580
                videoUrl: ``,
                previewUrl: '',
                title: 'powerManagementCPE250Title',
                text: 'powerManagementCPE250Descr',
                lineHeight: 0,
                lineWidth: 10,
                titlePosition: 'left',
                infoPosition: 'right'
            }
        ]
    }
}
